.value {
    float: right;
    border-radius: 5px;
    font-weight: inherit;
    font-size: 1.2em;
}

.rating {
  background-color: #777;
}

.rating-white {
  background-color: #FFF;
  border: solid 1px black;
}

.rating,
.rating.rating-warning,
.rating.rating-alert,
.rating.rating-ok {
    width: $rating-width;
    color: white;
}

.rating-warning {
    color: $warning-color;
    background-color: $warning-color;
}

.rating-alert {
    color: $alert-color;
    background-color: $alert-color;
}

.rating-ok {
    color: $ok-color;
    background-color: $ok-color;
}

.rating-off {
  background-color: $badge-bg;
}

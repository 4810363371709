/*!
 *
 * Angle - Bootstrap Admin App + jQuery
 *
 * Version: 3.4
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Override bootstrap variables
@import "app/variables";

// Bootstrap
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";

// Bootstrap reset
@import "app/bootstrap-reset";

// Typography
@import "app/typo";

// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";

// Form elements
@import "app/form-elements";
@import "app/form-validation";
@import "app/form-tags";
@import "app/form-wizard";
@import "app/form-imgcrop";

// Components
@import "app/breadcrumbs";
@import "app/user-block";
@import "app/animate";

//Plugins
@import "app/plugins";
@import "app/spinner";

// Utilities
@import "app/utils";

// Print CSS
@import "app/print";

//Theme
@import "themes/theme-d";

@import "app/patient";

@import "app/exercise-builder";

@import "app/simulator/main";

@import "app/visualization";

@import "app/simulation/result";
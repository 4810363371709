.navbar {
  &.page-controls {
    margin: 10px 5px 0px 10px;

    .container {
      width: calc(100% - 30px);
    }
  }
  .navbar-brand {
    &.dropdown-toggle {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .nav-icons {
    font-size: 18pt;
    padding-top: 10px;
    text-align: center;
    position: relative;

    li > a {
      padding-top: 5px;
      padding-bottom: 5px;

      color: #666;

      &:hover, &:focus {
        color: #333;
      }
    }
  }
}

.nav-icons {
  .fa {
    width: 48px;
    height: 48px;
    line-height: 48px;
  }
  .x-ray-icon {
    width: 48px;
    height: auto;
  }
}

.chart-container {
    position: relative;
    background-color: black;
}

.dygraph-axis-label {
    font-size: 9px;
    color: white;
}

.chart-label {
    float: right;
    position: absolute;
    right: 1px;
    width: 85px;
    color: grey;
    text-align: left;
    background-color: black;
    line-height: 18px;
    border: solid 1px black;
    border-radius: 5px;
    padding-bottom: 5px;
    padding-top: 5px;
    padding-left: 5px;
    vertical-align: top;
}

.dropdown-label {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.dropdown-input {
    margin-top: 15px;
    margin-bottom: 15px;
    margin-right: 15px;
}

#autoscale, #slider {
    outline: none;
}

.chart-widget {
    #mechanics {
        .row.middle-aligned {
            height: 28px;
            line-height: 28px;
            margin-bottom: 10px;

            .col-xs-2 {
                vertical-align: middle;
            }

            .form-control {
                height: 28px;
            }
        }
    }
}

ul.chart-scale {
    position: absolute;
    list-style: none;
    left: 20px;
    padding: 0px;
    margin: 0px;
    top: unquote("calc((90% - 60px) / 2)");
    z-index: 1000;

    li {
      line-height: 30px;
      vertical-align: middle;
      display: list-item;

      button {
        background-color: black;

        i {
          font-size: large
        }
      }

      a {
        font-size: 2em;
      }
    }
}

ul.timespan {
    width: 100%;
    list-style: none;
    position: relative;
    margin-top: -15px;

    li {
      position: absolute;
      font-size: 10px;

      @include nthChild($child: 1, $spaces: 4);
      @include nthChild($child: 2, $spaces: 4);
      @include nthChild($child: 3, $spaces: 4);
      @include nthChild($child: 4, $spaces: 4);
    }
}

.chart-selector .icon {
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  width: 16px;
  height: 16px;

  &.lung_mechanics {
    background-image: url("../../../images/mechanics_icon.png");

    &.white {
      background-image: url("../../../images/mechanics_icon_white.png");
    }
  }

  &.abg {
    background-image: url("../../../images/abg_icon.png");
    &.white {
      background-image: url("../../../images/abg_icon_white.png");
    }
  }
}

#chart-widget .widget-body {
#abg {
    position: relative;

    #chart {
      position: absolute;
      top: -15px;
      left: 51px;
      z-index: 3;
    }

    img {
      /* display: none; */
      position: absolute;
      left: 9px;
      height: 361px;
      width: 537px;
      top: -17px;
    }
  }
}

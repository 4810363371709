//
// Xlung
// ---------------------------------------------------
//
$warning-color: rgb(241,102,35);
$alert-color: rgb(255, 0, 0);
$ok-color: #29A000;
$rating-width: 40%;
$manel-icon-path:         "../../../fonts/manel";

//
// Bootstrap
// ---------------------------------------------------
//
$font-size-base:          12px;
$icon-font-path:          "~bootstrap/fonts/";
$navbar-height:           40px;
$navbar-margin-bottom:    0px;
$navbar-default-bg:       #fff;
$navbar-default-border:   transparent;

//
// Font-Awesome
// ---------------------------------------------------
//
$fa-font-path: "~font-awesome/fonts";

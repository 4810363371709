
.simulationMonitor {
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: auto;
  margin: 0;
  padding: 0;
  
  &.fitContent {
    width: fit-content;
  }

  li {
    width: 100%;
    height: 21px;
    position: relative;
    margin-bottom: 1px;
    border: 1px solid transparent;
  }

  li.alarming {
    border: 1px dashed red;
    border-radius: 5px;
    color: red;

    .rating {
      background-color: red;
    }
  }
}

.monitor-widget {
    .panel {
      margin-bottom: 5px;
    }
    .panel-heading {
      cursor: pointer;
      padding: 6px 13px;
    }
}

@mixin transition($property, $duration, $function) {
  -webkit-transition-property: $property;
  -webkit-transition-duration: $duration;
  -webkit-transition-timing-function: $function;

  -moz-transition-property: $property;
  -moz-transition-duration: $duration;
  -moz-transition-timing-function: $function;

  -o-transition-property: $property;
  -o-transition-duration: $duration;
  -o-transition-timing-function: $function;

  transition-property: $property;
  transition-duration: $duration;
  transition-timing-function: $function;
}

@mixin col-xs($cols: 0) {
    display: block;
    width: (100%/12) * $cols;
    float: left;
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
}

@mixin border-radius($radius:5px) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin nthChild($child, $spaces: 5, $offset: 0) {
  &:nth-child(#{$child}) {
    left: percentage(($child - 1)/$spaces);
  }
}

@mixin centerer($horizontal: true, $vertical: true) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if ($horizontal) {
    left: 50%;
    transform: translate(-50%, 0);
  } @else if ($vertical) {
    top: 50%;
    transform: translate(0, -50%);
  }
}

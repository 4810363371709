.widget {
  width: 100%;
  height: 100%;

  &.collapsed {
    height: 45px;
    .widget-body {
      display: none;
      height: 0;
    }
  }

  .widget-body {
    position: relative;
    height: unquote("calc(100% - 45px)");
  }

  .navbar {
    .container {
      width: initial;
    }
  }

  .navbar-default {
    background: $navbar-default-bg;
    border: $navbar-default-border;
    margin-bottom: initial;
    min-height: initial;

    color: $text-color;

    .navbar-brand {
      color: inherit;
      height: initial;
      padding: 0px 15px;
    }

    .navbar-nav {
      li.dropdown {
        > a.dropdown-toggle {
          background: initial;
          color: $link-color;
        }
      }

      li.active {
        > a {
          color: white;
        }
      }

      li > a {
        color: $link-color;
        padding: 0px 10px;

        &:hover {
          color: $link-hover-color;
        }

      }

      .dropdown.open > a {
        color: $link-hover-color;
      }

    }
  }
}

.components {
    background-color: white;
    position: absolute;
    width: 50px;
    height: 100%;
    top: 0px;
    left: 0px;

    text-align: center;

    li {
        margin-top: 5px;
    }
}

.gridster {
    display: block;
    margin-left: auto;
    margin-right: auto;

    .gs-w {
        z-index: initial;
    }

    section header {
        margin-top: -15px;
        margin-right: -20px;
        margin-left: -20px;
    }
}

.visualization {
  header {
    margin-top: -5px;
  }

  ul {
    padding-left: 15px;
  }

  border: 1px solid #eee;
  border-radius: 5px;
  margin-bottom: 15px;
  padding: 5px 15px;
}

table.table {
  tbody {
    tr {
      &.correct {
        background-color: rgb(73 170 40 / 60%);
        color: #293a23;
      }

      &.incorrect {
        background-color: #f05050b3;
        color: #3e0f0f;
      }

      &.observed {
        background-color: grey;
      }
    }
  }
}

.answer {
  border: 1px solid rgb(204, 204, 204);
  width: 100%;
  min-width: 150px;
  padding: 1.5rem 1.5rem;
  background: #fafbfc;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  user-select: none;

  // user selected the correct answer
  &.correct {
    background-color: rgb(73 170 40 / 60%);
    color: #293a23;
  }

  &.incorrect {
    background-color: #ff4949;
    color: #4a0000;
  }
}

.simulator-smallfield {
    width: 30px;
}
.ventilator-widget {
    legend {
        margin-bottom: 5px;

        h6 {
            margin-bottom: 5px;
        }
    }

    .flow-pattern {
        width: 96px;

        button {
          width: 32px;
        }
    }

    #ventilator_alarms {
      height: 100%;
    }

    .form-horizontal {
        .form-group {
            margin-bottom: 0px;
            margin-left: 0px;
            margin-right: 0px;
            height: 26px;
        }
        .control-label {
            padding-top: 4px;
            .badge{

                color: white;
                border: none;
                cursor: help;

                &.activeAlarms {
                    background-color: $brand-danger;
                }
                &.inactiveAlarms {
                    background-color: $brand-success;
                }
                &.disabledAlarms {
                    background-color: $brand-warning;
                }
            }
        }
    }

    label {
        font-size: 1.15em;
        font-weight: 600;
        margin-bottom: 0px;
        display: inline-block;
    }

    .gender {
        vertical-align: initial;

        > button {
            font-size: 36pt;
            width: 80px;
            height: 82px;
        }
    }
    a[data-toggle=popover] {
        cursor: help;
        color: #555;

        .control-label {
          cursor: inherit;
        }
    }
}

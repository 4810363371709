@import 'bootstrap-sass/assets/stylesheets/bootstrap/mixins';

input[type=number] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    opacity: 1;
    margin: 0;
  }
  text-align: center;

  &.result {
    float: initial;
    height: initial;
  }
}

.exercise-step {
  min-height: 50px;
  padding-bottom: 15px;
  border-bottom: 1px dashed #eee;

  &:last-of-type {
    border-bottom: none;
  }
}

.slider.slider-horizontal {
  width: 96% !important;
  left: 2%;
}


$height: 250px;

.objective-select {
  width: 100%;
  height: $height;
  position: relative;
  padding: 6px 16px;
  border: 1px solid #dde6e9;
  border-radius: 4px;
  background-color: #fff;
  overflow-y: scroll;
  display: inline-block;
}

.objective-buttons {
  width: 10%;
  margin-left: 20px;
  line-height: 250px;
  vertical-align: top;

  button {
    height: $height/4;
    font-size: 18px;
    font-weight: bolder;
  }
}

.step-description {
  display: block;

  margin-bottom: 25px;
  margin-top: 15px;

  font-weight: bold !important;
  font-size: 16px;
}

.scenario-description {
  min-height: 300px;
  max-height: 300px;
  overflow-y: scroll;

  .public-DraftEditor-content {
    min-height: 250px;
  }
}

.fileupload {
  width: 80%;
  position: relative;
  left: 10%;
  margin-bottom: 20px;

  .fileupload-area {
    padding-top: 33px;
    min-height: 100px;
    border: 3px dashed;
    font-size: 18px;
    text-align: center;
  }

  .fileupload-button {
    @include center-block();
    margin-bottom: 20px;
  }

  .file-preview {
    margin: auto;
    overflow: hidden;

    .thumb {
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
      position: relative;
      left: 50%;
      top: 50%;
    }
  }
}

.patient-widget {
  a.info {
    cursor: help;
    text-decoration: none;
  }

  fieldset {
    padding-bottom: inherit;
    border-bottom: inherit;
    margin-bottom: inherit;

    h5 {
      font-weight: bold;
      color: $text-color;
    }
  }

  .form-group {
    padding-top: 5px;
    line-height: 20px;
    margin-bottom: initial;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .form-control-static {
    width: 65%;
    text-align: center;
    float: right;
  }

  select {
    text-indent: 25%;
  }

  input[type=number],
  select {
    width: 60%;
    float: right;
  }
}

.ventilator-parameters {
  a.info {
    cursor: help;
    text-decoration: none;
  }

  .form-group {
    padding-top: 5px;
    line-height: 20px;
    margin-bottom: initial;

    &:last-child {
      margin-bottom: 15px;
    }
  }

  .form-control,
  .form-control-static {
    float: right;
    width: 40%;

    &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
          opacity: 1;
          margin: 0;
      }
  }

  td > .form-control {
    width: 100%;
  }
  select {
    text-indent: 25%;
  }
}


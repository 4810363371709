/* ========================================================================
     Component: form-imgcrop
 ========================================================================== */

.img-container,
.img-preview {
  background-color: #f7f7f7;
  overflow: hidden;
  width: 100%;
  text-align: center;
}
.img-container {
  min-height: 200px;
  max-height: 340px;
  margin-bottom: 20px;
}

.docs-preview {
    margin-right: -15px;
    margin-bottom: 10px
}

.img-preview {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px
}

.img-preview>img {
    max-width: 100%
}

.preview-lg {
    width: 263px;
    height: 148px
}

.preview-md {
    width: 139px;
    height: 78px
}

.preview-sm {
    width: 69px;
    height: 39px
}

.preview-xs {
    width: 35px;
    height: 20px;
    margin-right: 0
}


.cropper-container img {
  display: block;
  width: 100%;
  min-width: 0 !important;
  max-width: none !important;
  height: 100%;
  min-height: 0 !important;
  max-height: none !important;
  image-orientation: 0deg !important;
}
@import '~react-grid-layout/css/styles';
@import 'variables';
@import 'mixins';

@import 'dropdown-enhancement';
@import 'bootstrap-switch';
@import 'widget';
@import 'gasometry';
@import 'simulationMonitor';
@import 'charts';
@import 'ventilation';
@import 'patient';
@import 'animation';
@import 'manel-icon';
@import 'oxygen';
@import 'oximeter';
@import 'config';
@import 'dropzone';
@import 'alarms';
@import 'backdrop';
@import 'navigationbar';
@import 'case-description';
@import 'sound-modal.scss';

.simulator {
  input[type='number'] {
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      opacity: 1;
      margin: 0;
    }

    width: 95px;
    height: 24px;
    padding: 6px 10px;
    text-align: center;
    font-size: 1em;
    font-weight: 600;
    line-height: 18px;
    float: right;
    border-radius: 5px;
  }
}
.bootstrap-switch {
  .bootstrap-switch-mini-container {
    height: 23px;
  }

  .bootstrap-switch-mini-handle-on,
  .bootstrap-switch-mini-handle-off,
  .bootstrap-switch-mini-label {
    padding: 3px 5px;
    font-size: 11px;
  }
}
.simulator {
  > .panel-heading {
    padding-bottom: 0px;
  }

  .panel-body {
    padding-top: 0px;
  }
}

.xlung-nav {
  margin-top: 20px;
}

.logo {
  float: left;
  font-size: 18px;
  font-weight: 700;
  line-height: 50px;
  margin: 10px 0 10px;
  text-align: center;

  o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  webkit-transition: width 0.3s ease-in-out;

  width: 182px;
  height: 40px;
  display: block;
  text-indent: -9000px;
  background: url('../../../images/logo.png') no-repeat;
  background-size: contain;

  .role {
    display: inline;
    position: relative;
    margin-left: 184px;
    color: #973809;
    top: -38px;
  }

  a {
    width: 182px;
    height: 50px;
    display: block;
    overflow: hidden;
    text-indent: -9000px;
    color: #fff;
    text-decoration: none;
  }
}

.full-width {
  width: 100%;
}

.commit-button {
  text-align: center;
  @include centerer();
}

.spinner {
  width: 100%;
  min-width: 100px;
}

.backstage {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.25;
  z-index: 1;
}

.exercise-rating {
  color: #f0b518;
  font-weight: bolder;
  font-size: 12px;
}

.exercise-toggle {
  font-size: 23pt;
  position: relative;
  top: -5px;
}

.modal-dialog {
  width: 50%;
  font-size: 14px;

  .modal-body {
    margin-left: 15px;
  }
}

.splitter {
  height: 100%;
  border-left: 3px solid black;
}

a[disabled] {
  color: #aaa !important;
  cursor: not-allowed;
  opacity: 1;

  &:hover {
    color: #aaa !important;
  }
  &:focus {
    color: #aaa !important;
  }
}
.popover-content p {
  text-align: justify;
}

.unit {
  font-size: 0.9em;
  font-weight: 300;
}

.rating30 {
  width: 30%;
}

.badge {
  padding: 3px 5px;
}

.rating-disabled {
  color: #b5b5b5;
}

.result-activated {
  font-weight: bold;
}

.beta-text {
  display: inline-block;
  background-color: #fff38e;
  color: #0b0c1b;
  border-radius: 0.2em;
  padding: 0.2em 0.2em 0.1em;
  margin-left: 0.4em;
  font-weight: 700;
  line-height: 1;
  font-size: 1.1rem;
  position: relative;
  top: -0.2em;
}

.pro-label-activated {
  display: inline-block;
  background-color: #19a412;
  color: #ffffff;
  border-radius: 0.2em;
  padding: 0.2em 0.2em 0.1em;
  margin-left: 0.4em;
  font-weight: 700;
  line-height: 1;
  font-size: 1.1rem;
  position: relative;
  top: -0.2em;
  left: -0.5em;
}

.pro-label-deactivated {
  display: inline-block;
  background-color: #999999;
  color: #ffffff;
  border-radius: 0.2em;
  padding: 0.2em 0.2em 0.1em;
  margin-left: 0.4em;
  font-weight: 700;
  line-height: 1;
  font-size: 1.1rem;
  position: relative;
  top: -0.2em;
  left: -0.5em;
}

.isDisabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.isDisabled > a {
  color: currentColor;
  display: inline-block; /* For IE11/ MS Edge bug */
  pointer-events: none;
  text-decoration: none;
}

.customeTheme {
  color: white !important;
  background-color: #1b354b !important;
  border-radius: 15px !important;
  &.place-top {
    &:after {
      border-top-color: #1b354b !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}

#student-results-bloodgas-panel {
  height: 300px;
  font-weight: 'bold';
}

#student-results-monitorization-panel {
  height: 300px;
  font-weight: bold;
}

#student-results-adjustments-panel {
  height: 300px;
  font-weight: bold;
}

.result-bottom-panel {
  position: absolute;
  bottom: 30px;
}

.resultsTooltip {
  display: block !important;
  color: red !important;
  width: 250px;
  height: max-content;

  overflow-wrap: break-word;
  word-break: break-all;
  word-wrap: break-word;
  color: white !important;
  background-color: #1b354b !important;
  border-radius: 10px !important;
  &.place-top {
    &:after {
      border-top-color: #1b354b !important;
      border-top-style: solid !important;
      border-top-width: 6px !important;
    }
  }
}

/* ========================================================================
     Component: utils
 ========================================================================== */

// Margins
// --------------------

.m0 { margin: 0 !important; }
.ml0 { margin-left: 0 !important; }
.mr0 { margin-right: 0 !important; }
.mt0 { margin-top: 0 !important; }
.mb0 { margin-bottom: 0 !important; }

.m  { margin: $margin-base !important; }
.ml { margin-left: $margin-base !important; }
.mr { margin-right: $margin-base !important; }
.mt { margin-top: $margin-base !important; }
.mb { margin-bottom: $margin-base !important; }

.m-sm  { margin: $margin-sm !important; }
.ml-sm { margin-left: $margin-sm !important; }
.mr-sm { margin-right: $margin-sm !important; }
.mt-sm { margin-top: $margin-sm !important; }
.mb-sm { margin-bottom: $margin-sm !important; }

.m-lg  { margin: $margin-lg !important; }
.ml-lg { margin-left: $margin-lg !important; }
.mr-lg { margin-right: $margin-lg !important; }
.mt-lg { margin-top: $margin-lg !important; }
.mb-lg { margin-bottom: $margin-lg !important; }

.m-xl  { margin: $margin-xl !important; }
.ml-xl { margin-left: $margin-xl !important; }
.mr-xl { margin-right: $margin-xl !important; }
.mt-xl { margin-top: $margin-xl !important; }
.mb-xl { margin-bottom: $margin-xl !important; }

// Margin vertical / horizontal
.mv { @extend .mt; @extend .mb; }
.mh { @extend .ml; @extend .mr; }

.mv-lg { @extend .mt-lg; @extend .mb-lg; }
.mh-lg { @extend .ml-lg; @extend .mr-lg; }

.mv-sm { @extend .mt-sm; @extend .mb-sm; }
.mh-sm { @extend .ml-sm; @extend .mr-sm; }

// Paddings
// --------------------

.p0 { padding: 0 !important; }
.pl0 { padding-left: 0 !important; }
.pr0 { padding-right: 0 !important; }
.pt0 { padding-top: 0 !important; }
.pb0 { padding-bottom: 0 !important; }
.pv0 { @extend .pt0; @extend .pb0; }
.ph0 { @extend .pl0; @extend .pr0; }

.p  { padding: $padding-base !important; }
.pl { padding-left: $padding-base !important; }
.pr { padding-right: $padding-base !important; }
.pt { padding-top: $padding-base !important; }
.pb { padding-bottom: $padding-base !important; }

.p-sm  { padding: $padding-sm !important; }
.pl-sm { padding-left: $padding-sm !important; }
.pr-sm { padding-right: $padding-sm !important; }
.pt-sm { padding-top: $padding-sm !important; }
.pb-sm { padding-bottom: $padding-sm !important; }

.p-lg  { padding: $padding-lg !important; }
.pl-lg { padding-left: $padding-lg !important; }
.pr-lg { padding-right: $padding-lg !important; }
.pt-lg { padding-top: $padding-lg !important; }
.pb-lg { padding-bottom: $padding-lg !important; }

.p-xl  { padding: $padding-xl !important; }
.pl-xl { padding-left: $padding-xl !important; }
.pr-xl { padding-right: $padding-xl !important; }
.pt-xl { padding-top: $padding-xl !important; }
.pb-xl { padding-bottom: $padding-xl !important; }


// Padding vertical / horizontal
.pv { @extend .pt; @extend .pb; }
.ph { @extend .pl; @extend .pr; }

.pv-xl { @extend .pt-xl; @extend .pb-xl; }
.ph-xl { @extend .pl-xl; @extend .pr-xl; }

.pv-lg { @extend .pt-lg; @extend .pb-lg; }
.ph-lg { @extend .pl-lg; @extend .pr-lg; }

.pv-sm { @extend .pt-sm; @extend .pb-sm; }
.ph-sm { @extend .pl-sm; @extend .pr-sm; }


// Border
// --------------------

.b0 { border-width: 0 !important; }
.bl0 { border-left-width: 0 !important; }
.br0 { border-right-width: 0 !important; }
.bt0 { border-top-width: 0 !important; }
.bb0 { border-bottom-width: 0 !important; }

.br { border-right: 1px solid $border-color;}
.bl { border-left: 1px solid $border-color;}
.bt { border-top: 1px solid $border-color;}
.bb { border-bottom: 1px solid $border-color;}
.b, .ba  { @extend .br;@extend .bl;@extend .bt;@extend .bb; } // all borders

// Border Radius Clear

.radius-clear {
  border-radius: 0 !important;
}

// Box Shadow Clear

.shadow-clear {
  box-shadow: 0 0 0 #000 !important;
}

// Text helpers

.text-white         { color: #fff; }
.text-inverse       { color: $brand-inverse; }
.text-alpha         { color: $text-alpha; }
.text-pink          { color: $brand-pink; }
.text-purple        { color: $brand-purple; }
.text-dark          { color: $brand-dark; }
.text-alpha-inverse { color: $text-alpha-inverse; }
.text-green         { color: $brand-green; }
.text-yellow        { color: $brand-yellow; }
.text-gray-darker   { color: $gray-darker; }
.text-gray-dark     { color: $gray-dark; }
.text-gray          { color: $gray; }
.text-gray-light    { color: $gray-light; }
.text-gray-lighter  { color: $gray-lighter; }
.text-inherit       { color: inherit; }
// Text sizing

.text-sm { font-size: $text-sm; }
.text-md { font-size: $text-md; }
.text-lg { font-size: $text-lg; }

// Text others

.text-nowrap { white-space: nowrap; }
.text-thin   { font-weight: 100 !important; }
.text-normal { font-weight: normal !important; }
.text-bold   { font-weight: bold !important; }

.inline { display: inline-block !important; }
.block-center { margin: 0 auto; }

// Background helpers
//  Generates classes for
//    .brand
//    .brand-light
//    .brand-dark


.bg-primary      { @include bgcolor-variant('primary'); }
.bg-success      { @include bgcolor-variant('success'); }
.bg-info         { @include bgcolor-variant('info'); }
.bg-warning      { @include bgcolor-variant('warning'); }
.bg-danger       { @include bgcolor-variant('danger'); }
.bg-green        { @include bgcolor-variant('green'); }
.bg-pink         { @include bgcolor-variant('pink'); }
.bg-purple       { @include bgcolor-variant('purple'); }
.bg-inverse      { @include bgcolor-variant('inverse'); }
.bg-yellow       { @include bgcolor-variant('yellow'); }

.bg-white        { @include make-bg(#fff, inherit); }
.bg-gray-darker  { @include make-bg($gray-darker); }
.bg-gray-dark    { @include make-bg($gray-dark); }
.bg-gray         { @include make-bg($gray, $text-color); }
.bg-gray-light   { @include make-bg($gray-light, $text-color); }
.bg-gray-lighter { @include make-bg($gray-lighter, $text-color); }

.bg-transparent  { background-color: transparent !important; }

.bg-cover {
  background-size: cover;
}

// Common thumbnail sizes (ideal for images)

.thumb8   { @include thumnbail-size(); }
.thumb16  { @include thumnbail-size(16px); }
.thumb24  { @include thumnbail-size(24px); }
.thumb32  { @include thumnbail-size(32px); }
.thumb48  { @include thumnbail-size(48px); }
.thumb64  { @include thumnbail-size(64px); }
.thumb80  { @include thumnbail-size(80px); }
.thumb96  { @include thumnbail-size(96px); }
.thumb128 { @include thumnbail-size(128px); }


// Vertical align helpers

.align-middle {
  vertical-align: middle;
}
.align-top {
  vertical-align: top;
}
.align-bottom {
  vertical-align: bottom;
}


// Background image
.bg-center {
  background-position: center center;
  background-size: cover;
}

// List
.list-icon {
  em {
    font-size: 14px;
    width: 40px;
    vertical-align: middle;
    margin: 0;
    display: inline-block;
    text-align: center;
    @include transition(all .2s);
    line-height: 30px;
  }
  div:hover em {
    @include scale(3, 3)
  }
}

// Components size

.wd-xxs  { width: $wd-xxs;  }
.wd-xs   { width: $wd-xs;   }
.wd-sm   { width: $wd-sm;   }
.wd-sd   { width: $wd-sd;   }
.wd-md   { width: $wd-md;   }
.wd-lg   { width: $wd-lg;   }
.wd-xl   { width: $wd-xl;   }
.wd-xxl  { width: $wd-xxl;  }
.wd-wide { width: $wd-wide; }
.wd-auto { width: $wd-auto; }
.wd-zero { width: $wd-zero; }

// Extra labels

.label-inverse {
  @include label-variant($brand-inverse);
}

.label-green {
  @include label-variant($brand-green);
}

.label-pink {
  @include label-variant($brand-pink);
}

.label-purple {
  @include label-variant($brand-purple);
}


// Extra alert

.alert-purple {
  @include alert-variant($brand-purple, $brand-purple, #fff);
}
.alert-green {
  @include alert-variant($brand-green, $brand-green, #fff);
}
.alert-pink {
  @include alert-variant($brand-pink, $brand-pink, #fff);
}
.alert-inverse {
  @include alert-variant($brand-inverse, $brand-inverse, #fff);
}

// Simulate clickable element
.clickable {
  cursor: pointer;
}

// Absolute center element
// -----------------------------------

.abs-center-container {
  position: relative;
}
.abs-center {
  // width: 50%;
  // height: 50%;
  height: fit-content;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;

  &.abs-fixed {
    position: fixed;
    z-index: 999;
  }

  &.abs-right {
    left: auto; right: 20px;
    text-align: right;
  }

  &.abs-left {
    right: auto; left: 20px;
    text-align: left;
  }
}

@media (max-height: 720px) {
  .abs-center {
    position: relative;
  }
}


.link-unstyled {
  text-decoration: none !important;
  outline: none !important;
}

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%;
}

/**
 * Displays hidden content on hover. Add this class to the parent element.
 * Markup
 *   <div class="hover-visible">
 *    <div class="hover-hidden"></div>
 *   </div>
 */

.hover-visible {

  .hover-hidden {
    display: none;
  }

  &:hover .hover-hidden {
    display: block;
  }
  &:hover .hover-inline {
    display: inline-block;
  }
}

//
// MEDIA BOX CLASSIC
//
// since BS 3.3.x it doesn't support fullwidth form-controls so we use
// a copy to make possible the use of the classic component
// -----------------------------------


// Media objects
// Source: http://stubbornella.org/content/?p=497
// --------------------------------------------------


// Common styles
// -------------------------

// Clear the floats
.media-box,
.media-box-body {
  overflow: hidden;
  zoom: 1;
}

// Proper spacing between instances of .media-box
.media-box,
.media-box .media-box {
  margin-top: 15px;
}
.media-box:first-child {
  margin-top: 0;
}

// For images and videos, set to block
.media-box-object {
  display: block;
}

// Reset margins on headings for tighter default spacing
.media-box-heading {
  margin: 0 0 5px;
}


// Media image alignment
// -------------------------

.media-box {
  > .pull-left {
    margin-right: 10px;
  }
  > .pull-right {
    margin-left: 10px;
  }
}


// Media list variation
// -------------------------

// Undo default ul/ol styles
.media-box-list {
  padding-left: 0;
  list-style: none;
}

.full-width {
  width: 100% !important;
}

.full-height {
  height: 100% !important;
}
